<template>
  <MainLayout
    scroll-toolbar
    :bg-color="'white'"
  >
    <template #toolbar>
      <div class="relative flex items-center justify-between px-5 py-3.5 border-b border-border-normal">
        <div class="flex items-center">
          <img
            src="../../assets/icons/spyder.png"
            class="w-10 h-10"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Spyder
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-52 overflow-visible whitespace-nowrap"
            >
              Track, save and analyze.
            </BaseText>
          </div>
        </div>
        <button
          class="flex items-center gap-1.5 absolute left-1/2 transform -translate-x-1/2
        pl-1 py-1 pr-3 rounded-full border border-border-normal hover:bg-neutral-25 hover:border-transparent"
          style="transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, opacity 100ms ease-in-out;"
          :class="loadingBrands ? 'opacity-0': 'opacity-100'"
          @click="showBuySpyderCreditsModal = true"
        >
          <img
            v-if="getSpyderSubscriptions.length > 0"
            src="../../assets/icons/spyder-token.png"
            class="w-5 h-5"
          >
          <img
            v-else
            src="../../assets/icons/spyder-token-disabled.png"
            class="w-5 h-5"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            {{ getSpyderSubscriptions?.length }}/{{ spyderLimit ? spyderLimit : 2 }} credits
          </BaseText>
        </button>
        <div class="flex items-center gap-3">
          <button
            id="spyder-brands-view-tutorial-button"
            class="flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md transition-colors hover:bg-neutral-25"
            @click="tutorialTimestamp = 58, showTutorialModal = true"
          >
            <PlayIcon class="text-icon-normal" />
            <div class="text-sm leading-5 font-medium text-text-muted">
              Tutorial
            </div>
          </button>
          <BaseButton
            primary
            icon-only
            @click="showModal = true"
          >
            <PlusIcon stroke="white" />
          </BaseButton>
        </div>
      </div>
    </template>
    <template #content>
      <div class="bg-white mb-10">
        <BaseLoadingLogo v-if="getSpyderSubscriptionsLoading || loadingBrands" />
        <!-- =============== FIRST TIME WELCOME VIEW =============== -->
        <div v-else-if="!getSpyderSubscriptionsLoading && !loadingBrands && getSpyderSubscriptions?.length === 0">
          <SpyderBrandsWelcomeView
            @openAddSpyderModal="initialModalView = $event, showModal = true"
            @openTutorialModal="tutorialTimestamp = 21, showTutorialModal = true"
          />
        </div>
        <!-- =============== MAIN VIEW ================ -->
        <div
          v-else-if="!(getSpyderSubscriptions?.length === 0 && !getSpyderSubscriptionsLoading) && !loadingBrands"
          class="flex flex-col gap-5 w-full"
        >
          <div
            v-for="(folder, index) in sortedFolders"
            :key="`folder-${index}`"
            class="rounded-xl border border-border-normal"
          >
            <div class="folder-header-grid-template w-full py-2.5">
              <div class="flex items-center gap-3 py-1.5 ml-4">
                <FolderIcon class="text-text-subdued" />
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ folder.name }}
                </BaseText>
              </div>
              <div class="flex items-center gap-3 px-3">
                <div class="relative">
                  <div
                    class="cursor-default"
                    @mouseenter="hoverFolderBrandInfo(index, true)"
                    @mouseleave="hoverFolderBrandInfo(index, false)"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-text-normal"
                    >
                      {{ folderBrandCounts[index] }} Spyder Brands
                    </BaseText>
                  </div>
                  <transition>
                    <div
                      v-if="!folderExpanded[index] && folderBrandInfoHovered[index]"
                      class="brand-info-tooltip flex flex-col gap-1 p-1 bg-neutral-700 rounded-lg w-max shadow-md"
                      style="min-width: 130px; max-width: 250px"
                    >
                      <div
                        v-for="(brand, brandIndex) in getBrandsInFolder(folder.id).slice(0, 7)"
                        :key="`brand-tooltip-row-${brand.id}-${brandIndex}`"
                        class="flex items-center gap-2 p-1"
                      >
                        <img
                          :src="brand.avatar"
                          class="w-6 h-6"
                          style="border-radius: 5px"
                        >
                        <BaseText
                          type="label"
                          size="sm"
                          class="text-white truncate"
                        >
                          {{ brand.name }}
                        </BaseText>
                      </div>
                      <div
                        v-if="folderBrandCounts[index] > 7"
                        class="flex items-center pt-0.5 pb-1.5 pl-1.5 pr-1"
                      >
                        <BaseText
                          type="body"
                          size="sm"
                          class="text-text-subdued"
                        >
                          +{{ folderBrandCounts[index] - 7 }} more
                        </BaseText>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-400"
                >
                  Created on
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                >
                  {{ new Date(folder.createdAt || defaultCreatedDate).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'}) }}
                </BaseText>
                <button
                  v-if="folderBrandCounts[index] > 0"
                  class="ml-auto mr-5 transition-transform duration-200"
                  :style="{ transform: folderExpanded[index] ? 'scaleY(-1)' : 'scaleY(1)' }"
                  @click="expandFolder(index)"
                >
                  <ChevronIcon
                    :width="24"
                    :height="24"
                    class-name="text-icon-normal"
                  />
                </button>
              </div>
            </div>
            <div
              :ref="`brand-folder-content-${index}`"
              :style="{ height: folderExpanded[index] ? `${(folderBrandCounts[index] + (folderBrandCounts[index] > 0 ? 1 : 0)) * 44}px` : '0px' }"
              class="brand-folder-content flex flex-col gap-2 px-2 w-full"
            >
              <!-- Brands table header -->
              <div
                v-if="folderBrandCounts[index] > 0"
                class="brand-grid-template rounded-lg bg-neutral-10"
              >
                <div class="pl-12 pr-4 py-2">
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-normal"
                  >
                    Name
                  </BaseText>
                </div>
                <div class="last-checked-column px-3 py-2">
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-normal"
                  >
                    Last checked
                  </BaseText>
                </div>
                <div class="px-3 py-2">
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-normal"
                  >
                    Data Since
                  </BaseText>
                </div>
                <div class="px-3 py-2">
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-normal"
                  >
                    Ads
                  </BaseText>
                </div>
                <div class="px-3 py-2">
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-normal"
                  >
                    Type
                  </BaseText>
                </div>
              </div>
              <!-- Brands rows -->
              <div
                v-for="(brand, brandIndex) in getBrandsInFolder(folder.id)"
                :key="`brand-${brand.id}-${brandIndex}`"
                class="w-full"
                :class="{'cursor-not-allowed': !brand.id}"
              >
                <router-link
                  :to="{ name: 'SpyderView', params: { id: brand.id } }"
                  class="brand-grid-template pr-0.5 cursor-pointer rounded-lg transition-colors hover:bg-neutral-10"
                  :disabled="!brand.id"
                  :event="brand.id ? 'click' : ''"
                  :class="{'bg-neutral-10': expandedBrandOptions === brand.id}"
                >
                  <div class="flex items-center gap-3 px-2 py-1">
                    <div class="w-7 h-7 rounded-md overflow-hidden flex-shrink-0">
                      <img
                        v-if="brand.avatar"
                        :src="brand.avatar"
                        class="w-7 h-7 rounded-md"
                      >
                      <AnalyticsSpinnerIcon
                        v-else
                        class="ml-2 mt-2"
                      />
                    </div>
                    <BaseText
                      type="label"
                      size="sm"
                      class="truncate"
                      :class="brand.id ? 'text-text-muted' : 'text-text-disabled'"
                      :style="brand.id ? '' : 'font-weight: 400;'"
                    >
                      {{ brand.name || 'In Progress...' }}
                    </BaseText>
                  </div>
                  <div class="last-checked-column px-3 py-2">
                    <BaseText
                      v-if="brand.name"
                      type="body"
                      size="sm"
                      class=" text-text-subdued"
                    >
                      {{ formatTime(brand?.subscription?.last_checked?.seconds) }} ago
                    </BaseText>
                    <AnalyticsSpinnerIcon v-else />
                  </div>
                  <div class="px-3 py-2">
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-text-subdued"
                    >
                      {{ formatDate(brand?.subscription?.createdAt) }}
                    </BaseText>
                  </div>
                  <div class="ad-status-grid-template px-3 py-2">
                    <div class="flex items-center gap-2 whitespace-nowrap">
                      <div
                        class="w-1.5 h-1.5 rounded-full flex-shrink-0"
                        :style="{backgroundColor: brand.analytics?.brandDisplayFormatCounts ? '#00A879' : '#DFE1E7'}"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.liveAdCounts?.active_count || 0 }} <span class="text-text-normal">Active</span>
                      </BaseText>
                    </div>
                    <div class="flex items-center gap-2 whitespace-nowrap">
                      <div
                        class="w-1.5 h-1.5 rounded-full flex-shrink-0"
                        style="background-color: #DFE1E7;"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.liveAdCounts?.inactive_count || 0 }} <span class="text-text-normal">Inactive</span>
                      </BaseText>
                    </div>
                  </div>
                  <div class="format-breakdown-grid-template px-3 py-2">
                    <div class="flex items-center gap-1.5">
                      <VideoFormatIcon
                        :width="16"
                        :height="16"
                        :stroke="brand.analytics?.brandDisplayFormatCounts ? '#5E6678' : '#A4ABB8'"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.brandDisplayFormatCounts?.video || 0 }}
                      </BaseText>
                    </div>
                    <div class="flex items-center gap-1.5">
                      <ImageFormatIcon
                        :width="16"
                        :height="16"
                        :stroke="brand.analytics?.brandDisplayFormatCounts ? '#5E6678' : '#A4ABB8'"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.brandDisplayFormatCounts?.image || 0 }}
                      </BaseText>
                    </div>
                    <div class="flex items-center gap-1.5">
                      <CarouselFormatIcon
                        :width="16"
                        :height="16"
                        :stroke="brand.analytics?.brandDisplayFormatCounts ? '#5E6678' : '#A4ABB8'"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.brandDisplayFormatCounts?.carousel || 0 }}
                      </BaseText>
                    </div>
                    <div class="flex items-center gap-1.5">
                      <DynamicFormatIcon
                        :width="16"
                        :height="16"
                        :stroke="brand.analytics?.brandDisplayFormatCounts ? '#5E6678' : '#A4ABB8'"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.brandDisplayFormatCounts?.dynamic || 0 }}
                      </BaseText>
                    </div>
                    <div class="flex items-center gap-1.5">
                      <OtherFormatIcon
                        :width="16"
                        :height="16"
                        :stroke="brand.analytics?.brandDisplayFormatCounts ? '#5E6678' : '#A4ABB8'"
                      />
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ brand.analytics?.brandDisplayFormatCounts?.other || 0 }}
                      </BaseText>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <button
                      v-on-clickaway="() => { expandedBrandOptions = null }"
                      class="relative brand-menu-button p-0.5 rounded-md z-20"
                      :class="expandedBrandOptions === brand.id ? 'active' : 'inactive'"
                      @click.stop.prevent="expandedBrandOptions = expandedBrandOptions === brand.id ? null : brand.id"
                    >
                      <KebabMenuIcon />
                      <transition name="option">
                        <div
                          v-if="expandedBrandOptions === brand.id"
                          class="brand-options flex flex-col gap-0.5 p-1 rounded-lg bg-white"
                        >
                          <button
                            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                            @click.stop.prevent="expandedBrandOptions = null, showChangeBrandFolderModal(brand)"
                          >
                            <ChangeFolderIcon class="transition-colors text-icon-normal group-hover:text-icon-muted" />
                            <BaseText
                              type="body"
                              size="sm"
                              class="transition-colors text-text-normal group-hover:text-text-muted"
                            >
                              Move
                            </BaseText>
                          </button>
                          <button
                            class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                            @click.stop.prevent="expandedBrandOptions = null, removeSpyderBrand(brand)"
                          >
                            <DeleteIcon class-name="transition-colors text-icon-normal group-hover:text-icon-muted" />
                            <BaseText
                              type="body"
                              size="sm"
                              class="transition-colors text-text-normal group-hover:text-text-muted"
                            >
                              Remove
                            </BaseText>
                          </button>
                        </div>
                      </transition>
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <button
            class="flex items-center justify-center gap-2 w-full py-2 rounded-xl transition-colors hover:bg-neutral-25"
            @click="showFolderModal = true"
          >
            <PlusIcon stroke="#5E6678" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-normal"
            >
              Create new folder
            </BaseText>
          </button>
        </div>

        <AddSpyderModal
          v-if="showModal"
          :initial-view="initialModalView"
          :spyder-subscriptions="getSpyderSubscriptions"
          :spyder-limit="spyderLimit"
          :page-id-to-add="modalPageIdToAdd"
          :initial-folder="modalInitialFolder"
          @spyderAdded="pollUnprocessedBrands"
          @close="showModal = false, initialModalView = 'search'"
          @buyCredits="showBuySpyderCreditsModal = true"
          @openTutorialModal="tutorialTimestamp = 58, showTutorialModal = true"
        />
        <BuySpyderCreditsModal
          v-if="showBuySpyderCreditsModal"
          :current-sub-count="getSpyderSubscriptions?.length"
          :spyder-limit="spyderLimit"
          @close="showBuySpyderCreditsModal = false"
        />
        <FolderModal
          v-if="showFolderModal"
          @close="showFolderModal = false"
        />
        <ChangeFolderModal
          v-if="showChangeFolderModal"
          :item="changeFolderBrand"
          item-type="brand"
          @close="showChangeFolderModal = false, changeFolderBrand = null"
        />
        <SpyderTutorialModal
          v-if="showTutorialModal"
          :timestamp="tutorialTimestamp"
          @close="showTutorialModal = false"
        />
      </div>
    </template>
  </mainlayout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import SpyderBrandsWelcomeView from '../../components/spyderV2/SpyderBrandsWelcomeView.vue'
import MainLayout from '../../layouts/MainLayout.vue'
import FolderModal from '../../components/sidebar/FolderModal.vue'
import ChangeFolderModal from '../../components/globals/ChangeFolderModal.vue'
import AddSpyderModal from '../../components/spyderV2/AddSpyderModal.vue'
import BuySpyderCreditsModal from '../../components/spyderV2/BuySpyderCreditsModal.vue'
import SpyderTutorialModal from '../../components/spyderV2/SpyderTutorialModal.vue'
import firebase from '../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import { memoize } from 'lodash';

// Icons
import FolderIcon from '../../components/globals/Icons/SpyderV2Icons/FolderIcon.vue'
import VideoFormatIcon from '../../components/globals/Icons/FilterIcons/VideoFormatIcon.vue'
import ImageFormatIcon from '../../components/globals/Icons/FilterIcons/ImageFormatIcon.vue'
import CarouselFormatIcon from '../../components/globals/Icons/FilterIcons/CarouselFormatIcon.vue'
import DynamicFormatIcon from '../../components/globals/Icons/FilterIcons/DynamicFormatIcon.vue'
import OtherFormatIcon from '../../components/globals/Icons/FilterIcons/OtherFormatIcon.vue'
import AnalyticsSpinnerIcon from '../../components/globals/Icons/SpyderV2Icons/AnalyticsSpinnerIcon.vue'
import ChevronIcon from '../../components/globals/Icons/ChevronIcon.vue'
import PlayIcon from '../../components/globals/Icons/PlayIcon.vue'
import PlusIcon from '../../components/globals/Icons/PlusIcon.vue'
import KebabMenuIcon from '../../components/globals/Icons/KebabMenuIcon.vue'
import ChangeFolderIcon from '../../components/globals/Icons/ChangeFolderIcon.vue'
import DeleteIcon from '../../components/globals/Icons/DeleteIcon.vue'

export default {
  name: 'SpyderBrandsView',
  components: {
    AddSpyderModal,
    AnalyticsSpinnerIcon,
    BuySpyderCreditsModal,
    CarouselFormatIcon,
    ChangeFolderIcon,
    ChangeFolderModal,
    ChevronIcon,
    DeleteIcon,
    DynamicFormatIcon,
    FolderIcon,
    FolderModal,
    ImageFormatIcon,
    KebabMenuIcon,
    MainLayout,
    OtherFormatIcon,
    PlayIcon,
    PlusIcon,
    SpyderBrandsWelcomeView,
    SpyderTutorialModal,
    VideoFormatIcon
  },
  mixins: [clickaway],
  data () {
    return {
      brandAnalytics: [],
      loadingBrands: true,
      brands: [],
      subscriptions: [],
      favoritedBrands: [],
      infiniteId: +new Date(),
      defaultCreatedDate: new Date(),
      showModal: false,
      initialModalView: 'search',
      modalPageIdToAdd: '',
      modalInitialFolder: { id: null, name: 'Default Folder' },
      showFolderModal: false,
      folderExpanded: [],
      folderBrandInfoHovered: [],
      showTutorialModal: false,
      tutorialTimestamp: 58,
      pastWeekAdsByBrands: {},
      doneLoadingLastWeekStats: false,

      // Spyder Subscription (new)
      showBuySpyderCreditsModal: false,

      // Spyder Subscription
      showManageBoardModal: false,
      showChangeFolderModal: false,
      showAdvertisementModal: false,
      spyderLimit: 2,
      expandedBrandOptions: null,
      changeFolderBrand: null,

      // Intervals
      brandIntervals: []
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getStripeCustomer', 'getUserSubscription', 'getUserFreeTrialDate', 'isFreeTier', 'getTeamSubscriptionState']),
    ...mapGetters('BoardsModule', ['getFolders']),
    ...mapGetters('SpyderModule', ['getSpyderSubscriptions', 'getSpyderSubscriptionsLoading']),
    sortedFolders () {
      // We want empty folders (besides the default folder) to be at the bottom
      const nonEmptyFolders = this.getFolders.filter(folder => (folder?.spyderBrands?.length || 0) > 0)
      const emptyFolders = this.getFolders.filter(folder => (folder?.spyderBrands?.length || 0) === 0)
      return [{ id: null, name: 'Default Folder' }, ...nonEmptyFolders, ...emptyFolders]
    },
    hasSubscription () {
      // check if a week has passed since trial and if they're free tier
      if (this.getTeam && !['past_due', 'canceled'].includes(this.getTeamSubscriptionState)) {
        return true
      }

      return !this.isFreeTier
    },
    folderBrandCounts () {
      return [this.getBrandsInFolder(null).length, ...this.sortedFolders.slice(1).map(folder => this.getBrandsInFolder(folder.id).length || 0)]
    }
  },
  watch: {
    getFolders: { // Ensure all the folders have an expanded/hover state mapped in the array (+1 for default)
      immediate: true,
      handler () {
        this.folderExpanded = [...this.getFolders.map(() => true), true]
        this.folderBrandInfoHovered = [...this.getFolders.map(() => false), false]
        this.removeTransitionListeners()
        this.attachTransitionListeners()
      }
    }
  },
  async mounted () {
    try {
      await this.fetchSpyderSubscriptions()
      this.spyderLimit = await this.fetchSpyderLimit()
    } catch (error) {
      // For some reason, these calls fail when the user navigates here first thing after creating an account.
      // The bug is possibly related to broken vuex intitializations when the user first completes signup.
      // This is a TEMPORARY hack fix that refreshes the page if the calls fail
      this.spyderLimit = 2
      console.error(error)
      location.reload()
    }

    // Get the account's created date (used for the default folder's "Created on" date)
    const userCreated = firebase.auth().currentUser.metadata.creationTime
    this.defaultCreatedDate = new Date(userCreated)

    if (this.$route.query.id) {
      this.initialModalView = 'manual'
      this.modalPageIdToAdd = this.$route.query.id
      this.showModal = true
      history.replaceState({}, null, this.$route.path) // Clear the query params
    } else if (this.$route.query.folderId) {
      const folderId = this.$route.query.folderId
      if (folderId !== 'default') this.modalInitialFolder = this.getFolders.find(folder => folder.id === folderId)
      this.showModal = true
      history.replaceState({}, null, this.$route.path) // Clear the query params
    }

    this.pollUnprocessedBrands()
    this.attachTransitionListeners()

    // READD BELOW WHEN READY
    // this.getPastWeekAdsByBrands()
    this.brandAnalytics = await ForeplayAPI.Brands.getBrandBulkAnalytics()

    this.loadingBrands = false
  },
  beforeDestroy () {
    this.brandIntervals.forEach(interval => clearInterval(interval))
    this.removeTransitionListeners()
  },
  methods: {
    ...mapActions('SpyderModule', ['fetchSpyderSubscriptions', 'fetchSpyderLimit']),
    ...mapActions('AuthModule', ['setUserSubscription']),
    ...mapActions('BoardsModule', ['fetchFolders']),
    ...mapMutations('BoardsModule', ['SET_FOLDERS']),
    ...mapMutations('SpyderModule', ['SET_SPYDER_SUBSCRIPTIONS']),
    pollUnprocessedBrands () {
      const unprocessedBrands = this.getSpyderSubscriptions.filter(subscription => !subscription.subscription?.brandId)
      console.log('unprocessedBrands', unprocessedBrands)

      // Poll unprocessedBrands
      for (const spyderBrand of unprocessedBrands) {
        const intervalId = setInterval(async () => {
          // Check by page ID (fb_ad_page_track ID isn't available here)
          try {
            const spyderBrandData = await FirebaseAPI.Spyder.getByPageId(spyderBrand.subscription.pageId)

            if (spyderBrandData?.brandId) {
              clearInterval(intervalId)

              // Merge the brand and the subscription
              const brand = await FirebaseAPI.Brands.get(spyderBrandData.brandId)

              const updatedSubscription = { ...spyderBrandData, ...brand }
              const filteredSubscriptions = this.getSpyderSubscriptions.filter(
                subscription => subscription.subscription.pageId !== updatedSubscription.pageId
              )

              this.SET_SPYDER_SUBSCRIPTIONS([...filteredSubscriptions, updatedSubscription])
            }
          } catch (error) {
            console.error(error)
          }
        }, 500)

        this.brandIntervals.push(intervalId)
      }
    },
    closeSpyderModal () {
      this.showModal = false
    },
    openAdModal () {
      this.manualUpload = false
      this.showAdvertisementModal = true
    },
    expandFolder (index) {
      this.$refs[`brand-folder-content-${index}`][0].style.overflow = 'hidden'
      this.folderExpanded = this.folderExpanded.map((expanded, i) => i === index ? !expanded : expanded)
    },
    hoverFolderBrandInfo (index, isHovered) {
      this.folderBrandInfoHovered = this.folderBrandInfoHovered.map((hovered, i) => i === index ? isHovered : hovered)
    },
    formatTime (time) {
      const updatedAgo = ((new Date().getTime() - (time * 1000)) / 3600000)
      return updatedAgo < 1 ? `${Math.floor(updatedAgo * 60)}m` : `${Math.floor(updatedAgo)}h`
    },
    formatDate (dateMS) {
      const date = new Date(dateMS)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return date.toLocaleDateString('en-US', options)
    },
    getBrandsInFolder: memoize(function (folderId) {
      // Helper function for deep cloning
      const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

      // If folderId is null, get all brands not in a folder (Default Folder)
      if (!folderId) {
        const brands = this.getSpyderSubscriptions.filter(subscription =>
          !this.getFolders.find(folder => folder?.spyderBrands?.includes(subscription.subscription.pageId))
        )

        return brands.map(brand => {
          // Deep clone the brand
          const clonedBrand = deepClone(brand)

          // Overwrite whatever crap may be there - replace it with live data
          clonedBrand.analytics = this.brandAnalytics.find(analytics => analytics.pageId === clonedBrand.adLibraryId)

          if (!clonedBrand.analytics || !clonedBrand.analytics.brandDisplayFormatCounts) return clonedBrand
          const brandDisplayFormatCounts = clonedBrand.analytics.brandDisplayFormatCounts || {}

          // Get sums for other, dynamic and carousel
          clonedBrand.analytics.brandDisplayFormatCounts.carousel = (brandDisplayFormatCounts.carousel || 0) + (brandDisplayFormatCounts.multi_images || 0) + (brandDisplayFormatCounts.multi_videos || 0) + (brandDisplayFormatCounts.multi_medias || 0)
          clonedBrand.analytics.brandDisplayFormatCounts.dynamic = (brandDisplayFormatCounts.dco || 0) + (brandDisplayFormatCounts.dpa || 0)
          clonedBrand.analytics.brandDisplayFormatCounts.other = (brandDisplayFormatCounts.event || 0) + (brandDisplayFormatCounts.text || 0) + (brandDisplayFormatCounts['page like'] || 0)

          return clonedBrand
        })
      }

      // Get brands in folder
      const folder = this.getFolders.find(folder => folder.id === folderId)
      if (!folder.spyderBrands) return []

      return folder.spyderBrands.map(brandId => {
        const brand = this.getSpyderSubscriptions.find(subscription => subscription.subscription.pageId === brandId)

        if (!brand) return null

        // Deep clone the brand
        const clonedBrand = deepClone(brand)

        clonedBrand.analytics = this.brandAnalytics.find(analytics => analytics.pageId === clonedBrand?.adLibraryId)

        if (!clonedBrand?.analytics || !clonedBrand.analytics.brandDisplayFormatCounts) return clonedBrand

        const brandDisplayFormatCounts = clonedBrand.analytics?.brandDisplayFormatCounts || {}

        // Get sums for other, dynamic and carousel
        clonedBrand.analytics.brandDisplayFormatCounts.carousel = (brandDisplayFormatCounts.carousel || 0) + (brandDisplayFormatCounts.multi_images || 0) + (brandDisplayFormatCounts.multi_videos || 0) + (brandDisplayFormatCounts.multi_medias || 0)
        clonedBrand.analytics.brandDisplayFormatCounts.dynamic = (brandDisplayFormatCounts.dco || 0) + (brandDisplayFormatCounts.dpa || 0)
        clonedBrand.analytics.brandDisplayFormatCounts.other = (brandDisplayFormatCounts.event || 0) + (brandDisplayFormatCounts.text || 0) + (brandDisplayFormatCounts['page like'] || 0)

        return clonedBrand
      }).filter(brand => brand) // Filter out undefined brands (orphaned references)
    }),
    getFolderByPageId (pageId) {
      return this.getFolders.find(folder => folder?.spyderBrands?.includes(pageId))?.name || 'Default Folder'
    },
    showChangeBrandFolderModal (brand) {
      this.changeFolderBrand = brand
      this.showChangeFolderModal = true
    },
    attachTransitionListeners () {
      // Attach onTransitionEnd event listener to each collapsible folder
      this.$nextTick(() => {
        for (let i = 0; i <= this.getFolders.length; i++) {
          const folder = this.$refs[`brand-folder-content-${i}`][0]
          if (!folder) continue
          folder.addEventListener('transitionend', () => {
            if (this.folderExpanded[i]) {
              folder.style.overflow = 'visible'
            }
          })
        }
      })
    },
    removeTransitionListeners () {
      // Remove onTransitionEnd event listener from each collapsible folder
      for (let i = 0; i <= this.getFolders.length; i++) {
        const folder = this.$refs[`brand-folder-content-${i}`][0]
        if (!folder) continue
        folder.removeEventListener('transitionend', () => {
          if (this.folderExpanded[i]) {
            folder.style.overflow = 'visible'
          }
        })
      }
    },
    async removeSpyderBrand (brand) {
      const subscriptionId = this.getTeam?.id || this.getUser.user_id
      const adLibraryId = brand.subscription.pageId
      const removeSubscription = brand.subscription

      if (this.getFolderByPageId(adLibraryId) === 'Default Folder') {
        console.log('default folder brand removal')
        // the case for Default Folder
        const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
        await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
        this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))
        this.fetchSpyderSubscriptions()
        this.fetchFolders()
        return
      }
      // updating subscriptions
      const removePayload = removeSubscription.subscribers.filter(sub => sub !== subscriptionId)
      await FirebaseAPI.Spyder.update(removeSubscription.id, { subscribers: removePayload })
      this.SET_SPYDER_SUBSCRIPTIONS(this.getSpyderSubscriptions.filter(subscriptions => subscriptions.subscription.pageId !== adLibraryId))

      // updating folders
      const updatedFolder = this.getFolders.find(folder => folder?.spyderBrands?.includes(brand.subscription.pageId))
      const updatePayload = updatedFolder.spyderBrands.filter(brandId => brandId !== brand.subscription.pageId)

      FirebaseAPI.Folders.update(updatedFolder.id, { spyderBrands: updatePayload })
      this.SET_FOLDERS(this.getFolders.map(folder => folder.id === updatedFolder.id ? { ...folder, spyderBrands: updatePayload } : folder))
    }
    // READD BELOW WHEN READY
    // async getPastWeekAds (brandId) {
    //   try {
    //     const brandAdsCount = await FirebaseAPI.Brands.getPastSevenDaysAds(brandId)
    //     this.pastWeekAdsByBrands[brandId] = brandAdsCount
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    // async getPastWeekAdsByBrands () {
    //   for (const brand of this.getSpyderSubscriptions) {
    //     await this.getPastWeekAds(brand.id)
    //   }
    //   this.doneLoadingLastWeekStats = true
    // }
  }
}
</script>

<style scoped>
  /* Hide the last-checked column on narrower viewports */
  @media (max-width: 1600px) {
    .last-checked-column {
      display: none;
    }
    .brand-grid-template {
      grid-template-columns: 250px 180px 320px 1fr 32px !important;
    }
  }

  .folder-header-grid-template {
    display: grid;
    grid-template-columns: 220px 235px 1fr;
    align-items: center;
  }
  .brand-grid-template {
    display: grid;
    grid-template-columns: 250px 146px 180px 320px 1fr 32px;
    align-items: center;
  }
  .ad-status-grid-template {
    display: grid;
    grid-template-columns: 110px 1fr;
    align-items: center;
  }
  .format-breakdown-grid-template {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
  }
  .brand-info-tooltip {
    position: absolute;
    left: calc(100% + 6px);
    top: -9px;
  }
  .brand-folder-content {
    transition: height 200ms ease-in-out;
  }
  .brand-menu-button {
    transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out;
  }
  .brand-menu-button.active {
    background-color: #ECEFF3;
  }
  .brand-menu-button.inactive:hover {
    background-color: white;
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  }
  .brand-options {
    position: absolute;
    right: calc(100% + 4px);
    top: 50%;
    transform: translateY(-50%);
    width: 105px;
    z-index: 50;
    box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  }

  .v-enter-active, .v-leave-active {
    transition: opacity 150ms ease-in-out;
  }
  .v-enter-from, .v-enter, .v-leave-to {
    opacity: 0;
  }
  .v-enter-to, .v-leave-from {
    opacity: 1;
  }

  .option-enter-active, .option-leave-active {
    transition: opacity 100ms ease-in-out;
  }
  .option-enter-from, .option-enter, .option-leave-to {
    opacity: 0;
  }
  .option-enter-to, .option-leave-from {
    opacity: 1;
  }
</style>
